<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar" :class="avatarClass">
          <img :src="avatar" class="c-avatar-img" :class="avatarImgClass" />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownItem @click="logout()">
      <CIcon name="cil-account-logout" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      itemsCount: 42,
      avatar: null,
      avatarClass: '',
      avatarImgClass: ''
    };
  },
  methods: {
    fillAvatar() {
      var user = JSON.parse(localStorage.getItem("user"));
      if (user != null) {
        let result = JSON.parse(localStorage.getItem("user"));
        if (!result.picture) {
          this.avatar = '/img/avatars/default.svg';
          this.avatarClass = 'c-avatar--default';
          this.avatarImgClass = 'c-avatar-img--default';
        } else {
          this.avatar = result.picture;
        }
      }
    },
    logout() {
      let self = this;
      localStorage.clear();
      self.$router.push({ path: "/auth" });
    },
  },
  mounted: function () {
    this.fillAvatar();
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
.c-avatar--default {
  background: linear-gradient(to bottom, silver, lightgray);
}
.c-avatar-img {
  aspect-ratio: 1/1;
}
.c-avatar-img--default {
  height: 24px;
  width: 28px;
}
</style>