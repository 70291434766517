<template>
  <CFooter :fixed="false">
    <div>
      <a href="http://stayify.com/" target="_blank">STAYIFY</a>
      <span class="ml-1">&copy; {{ new Date().getFullYear() }}</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a target="_blank" href="http://stayify.com/">STAYIFY</a>
      <br/>
      Version 1.0.0
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>
