<script>
import store from "../store";
import axios from "axios";

export default {
  name: "nav",
  data() {
    return {
      result: [],
    };
  },
  computed: {
    sidebarItems() {
      return this.result;
    },

    getInstance() {
      return store.state.instance;
    },
  },
  methods: {
    rebuildData(data) {
      let self = this;
      self.result = [
        {
          _name: "CSidebarNav",
          _children: [
            {
              _name: "CSidebarNavDropdown",
              name: "Instance",
              route: "/dashboard",
              icon: "cilUser",
              items: [],
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("menu.dashboard"),
              to: "/dashboard",
              icon: "cil-speedometer",
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("menu.experiences"),
              to: "/experiences",
              icon: "cil-mood-very-good",
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("menu.contracts"),
              to: "/contracts",
              icon: "cil-spreadsheet",
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("menu.categories"),
              to: "/categories",
              icon: "cil-layers",
            },
            {
              _name: "CSidebarNavItem",
              name: "Reports",
              to: "/reports",
              icon: "cil-money",
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("menu.customers"),
              to: "/customers",
              icon: "cil-group",
            },
            {
              _name: "CSidebarNavItem",
              name: "Companies",
              to: "/companies",
              icon: "cil-briefcase",
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("menu.bookings"),
              to: "/bookings",
              icon: "cil-calendar-check",
            },
            {
              _name: "CSidebarNavItem",
              name: "Calendar",
              to: "/calendarmanagement",
              icon: "cil-calendar",
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("menu.coupons"),
              to: "/coupons",
              icon: "cil-gift",
            },

            {
              _name: "CSidebarNavDropdown",
              name: this.$t("menu.settings"),
              to: "/settings",
              icon: "cil-settings",
              items: [
                {
                  _name: "CSidebarNavItem",
                  name: "Closures",
                  to: "/settings/closures",
                  icon: "cilBan",
                },
                {
                  name: "Languages",
                  to: "/settings/languages",
                  icon: "cil-globe-alt",
                },
                {
                  name: "Integrations",
                  to: "/settings/integrations",
                  icon: "cil-globe-alt",
                },
                {
                  name: "Tax Classes",
                  to: "/settings/taxclasses",
                  icon: "cil-money",
                },

                {
                  _name: "CSidebarNavItem",
                  name: "General",
                  to: "/settings/general",
                  icon: "cil-settings",
                },
                {
                  _name: "CSidebarNavItem",
                  name: "Payment Methods",
                  to: "/settings/paymentmethods",
                  icon: "cilMoney",
                },
                {
                  name: "Inventories",
                  to: "/settings/inventories",
                  icon: "cil-cart",
                },
                {
                  name: "Policies",
                  to: "/settings/policies",
                  icon: "cil-file",
                },
                {
                  name: "Cancellation Policies",
                  to: "/settings/cancellationpolicies",
                  icon: "cil-file",
                },
                {
                  name: "Currencies",
                  to: "/settings/currencies",
                  icon: "cil-money",
                },
                {
                  name: "Templates",
                  to: "/settings/templates",
                  icon: "cil-file",
                },
              ],
            },

            {
              _name: "CSidebarNavItem",
              name: this.$t("menu.support"),
              to: "/support",
              icon: "cil-comment-square",
            },
          ],
        },
      ];
      self.result[0]["_children"][0]["items"] = [];
      for (let k = 0; k < data.partnerPermissions.length; k++) {
        self.sidebarItems[0]["_children"][0]["items"].push({
          name: data.partnerPermissions[k]["partnerName"],
          to:
            "/instance?partnerid=" +
            data.partnerPermissions[k]["partnerId"] +
            "&partnerName=" +
            data.partnerPermissions[k]["partnerName"],
          icon: "cil-caret-right",
        });
      }

      if (data.hasGlobalRights == true) {
        self.result[0]["_children"].push({
          _name: "CSidebarNavDropdown",
          name: this.$t("menu.globalSettings"),
          to: "/settings",
          icon: "cil-settings",
          items: [
            {
              name: "Accounts",
              to: "/accounts",
              icon: "cil-people",
            },
            {
              name: "Partners",
              to: "/partners",
              icon: "cil-briefcase",
            },
            {
              name: "Integrations",
              to: "/integrations",
              icon: "cil-globe-alt",
            },
            {
              name: "Languages",
              to: "/languages",
              icon: "cil-globe-alt",
            },
            {
              name: "Currencies",
              to: "/currencies",
              icon: "cil-money",
            },
            {
              name: "Experience Statuses",
              to: "/experiencestatuses",
              icon: "cil-zoom",
            },
            {
              name: "Finance",
              to: "/finance",
              icon: "cil-zoom",
            },
            {
              name: "Guest Groups",
              to: "/guestgroups",
              icon: "cil-group",
            },
            {
              name: "Guest Types",
              to: "/guesttypes",
              icon: "cil-layers",
            },
          ],
        });
      }
      return self.sidebarItems[0]["_children"][0];
    },
  },
  mounted: function () {
    let self = this;
    var user = localStorage.getItem("user");
    if (user != null) {
      self.rebuildData(JSON.parse(localStorage.getItem("user")));
    }
    //axios.get(`${this.$apiAdress}/v1/InventoryItem/MoveOldData`);
  },
};
</script>
  