<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CIcon name="logo" height="48" alt="Logo" />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard"> Dashboard </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <CHeaderNavItem>
        <div class="st-booking-icons pc-header-widget-icons" icons="cart user expsmgmt"></div>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3 c-d-legacy-none">
        <button
          @click="() => $store.commit('toggle', 'darkMode')"
          class="c-header-nav-btn"
        >
          <CIcon v-if="$store.state.darkMode" name="cil-sun" />
          <CIcon v-else name="cil-moon" />
        </button>
      </CHeaderNavItem>
      <TheHeaderDropdownAccnt />
    </CHeaderNav>

    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
  },
  methods: {
    loadWidget() {
      const user = localStorage.getItem("user");
      const userId = user && typeof user === 'string' && user.length > 0 ? JSON.parse(user).id : null;
      const partnerId = localStorage.getItem("partner_id");
      window.$stayify = {
        adminAccountId: userId,
        partnerId,
        api: {
          url: this.$widgetApi,
          apiVersion: 'v2'
        },
        languageCode: 'en-US',
        routerHistoryMode: 'hash'
      };
      let widgetScript = document.createElement('script');
      widgetScript.setAttribute('src', this.$widgetSrc);
      document.head.appendChild(widgetScript);
    }
  },
  mounted() {
    this.loadWidget();
    this.unwatch = this.$store.watch(
      (state) => state.partnerId,
      (newValue, _oldValue) => {
        window.$stayify.partnerId = newValue;
      }
    );
  },
  beforeDestroy() {
    this.unwatch();
  }
};
</script>

<style scoped>
.pc-header-widget-icons {
  align-items: flex-end;
}
</style>
